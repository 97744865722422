import React, { useEffect, useState, useCallback } from 'react';


// Gets dark mode from OS. Snatched this from the tutorial.
const prefersDarkMode = () => {
  return window.matchMedia("(prefers-color-scheme: dark)").matches === true;
};

const useDarkMode = (initialState: boolean): [boolean, boolean, () => void] => {
  const [isDarkMode, setDarkMode] = useState<boolean>(initialState);

  // Flag to avoid showing the light mode on app startup when the user
  // has set the preference for dark mode.
  const [isThemeReady, setThemeReady] = useState<boolean>(false);

  const toggleDarkMode = () => {
    const toggled = !isDarkMode;
    console.log('is toggling', toggled);
    localStorage.setItem('darkMode', JSON.stringify(toggled));
    setDarkMode(toggled);
  };

  useEffect(() => {
    const isDark = JSON.parse(localStorage.getItem('darkMode') as string);
    if (isDark) {
      setDarkMode(isDark)
    } else if (prefersDarkMode()) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }

    // Theme choice has fully loaded
    setThemeReady(true);
  }, []);

  return [isDarkMode, isThemeReady, toggleDarkMode];
}

const defaultState = {
  isDarkMode: false,
  toggleDarkMode: () => {
    console.log('default function')
  },
};

export const ThemeContext = React.createContext(defaultState);

export const ThemeProvider: React.FC = ({ children }) => {
  const [isDarkMode, isThemeReady, toggleDarkMode] = useDarkMode(defaultState.isDarkMode);

  return (
    <ThemeContext.Provider
      value={{
        isDarkMode,
        toggleDarkMode
      }}
    >
      {isThemeReady && children}
    </ThemeContext.Provider>
  )
}