import React from 'react';
import { ThemeProvider } from './src/components/ThemeProvider';

require("./src/styles/prism-darcula.css")
require("prismjs/plugins/command-line/prism-command-line.css")
require("react-toggle/style.css");

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider>
      {element}
    </ThemeProvider>
  );
};